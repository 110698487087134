.procurement-central-controls * {
    font-size: 7.8pt !important;
}

.ant-btn-sm {
    height: auto;
}

div.react-select__menu {
    z-index: 9999 !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover, td.ant-table-column-sort {
    background: initial;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
    background: initial;
}

.money {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
}

div.ant-affix {
    z-index: 300;
}

.money::before{
    content: "$";
    display: inline;
    padding-right: 0.15rem;
}