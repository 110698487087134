.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.global-par-lvl-calendar-date-picker {
    user-select: none;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}

.global-par-lvl-calendar-date-picker .ant-picker-content .selected {
    color: rgb(255, 255, 255);
    background: rgb(62, 121, 247);
    user-select: none;
}

.global-par-lvl-calendar-date-picker .ant-picker-content .gpl-date {
    border-radius: 50rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
}

.global-par-lvl-calendar-date-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    color: rgb(69, 85, 96);
    background: transparent;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 481px) {
    .floating-button {bottom: 100px; right: 20px}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 481px) and (max-width: 599px) {
    .floating-button {bottom: 100px; right: 60px}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .floating-button {bottom: 100px; right: 60px}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .floating-button {bottom: 50px; right: 50px}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .floating-button {bottom: 50px; right: 50px}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .floating-button {bottom: 50px; right: 50px}
}
